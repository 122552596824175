import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "revision-browser__option pb-64" }
const _hoisted_2 = { class: "video-player" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 0,
  class: "video-player__interface"
}
const _hoisted_5 = { class: "video-player__progress-bar-wrapper" }
const _hoisted_6 = { class: "video-player__comment-timeline" }
const _hoisted_7 = ["onMouseover", "onMouseleave", "onClick"]
const _hoisted_8 = { class: "video-player__comment-header" }
const _hoisted_9 = { class: "video-player__comment-times" }
const _hoisted_10 = { class: "video-player__comment-timestamp" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "video-player__controls" }
const _hoisted_13 = { class: "video-player__controls-set" }
const _hoisted_14 = { class: "video-player__volume-bar-wrapper" }
const _hoisted_15 = { class: "video-player__controls-set" }
const _hoisted_16 = {
  key: 0,
  class: "flex flex--justify-center"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("video", {
        ref: "video_player",
        class: "revision-browser__option",
        src: $props.option.video_url,
        alt: $props.option.title,
        onTimeupdate: $setup.updateCurrentTime,
        onDurationchange: $setup.updateDuration,
        onVolumechange: _cache[0] || (_cache[0] = (...args) => (_ctx.updateVolume && _ctx.updateVolume(...args))),
        onClick: $setup.togglePlay
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_3),
      ($setup.duration)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                id: "progress",
                name: "progress",
                type: "range",
                min: "0",
                max: "1000",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.video_progress) = $event)),
                class: "video-player__progress-bar",
                onInput: $setup.updateVideoTime
              }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
                [_vModelText, $setup.video_progress]
              ]),
              _createElementVNode("div", {
                class: "video-player__progress",
                style: _normalizeStyle($setup.video_progress_style)
              }, null, 4 /* STYLE */)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.timeline_comments, (comment, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `timeline-comment-${index}`,
                  class: _normalizeClass(["video-player__comment-container", { 'video-player__comment-container--show-comment': $setup.visible_comment_ids.includes( comment.id ) }]),
                  style: _normalizeStyle($setup.getTimelinePositionStyle( comment ))
                }, [
                  _createElementVNode("button", {
                    class: "video-player__comment-pip",
                    onMouseover: $event => ($setup.showComment( comment )),
                    onMouseleave: $event => ($setup.hideComment( comment )),
                    onClick: $event => ($setup.flashVideoFrame( comment.video_timestamp ))
                  }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_7),
                  _createElementVNode("div", {
                    class: "video-player__comment",
                    style: _normalizeStyle($setup.getCommentPositionStyle( comment ))
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("h3", null, _toDisplayString(comment.author.fullName), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString($setup.formatTimestamp( comment.video_timestamp )), 1 /* TEXT */),
                        _createElementVNode("span", null, _toDisplayString($setup.formatTimePassed( comment.dateCreated )), 1 /* TEXT */)
                      ])
                    ]),
                    _createElementVNode("div", {
                      class: "video-player__comment-body",
                      innerHTML: comment.comment_content
                    }, null, 8 /* PROPS */, _hoisted_11)
                  ], 4 /* STYLE */)
                ], 6 /* CLASS, STYLE */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("button", {
                  class: _normalizeClass(["video-player__play-button", $setup.paused ? 'video-player__play-button--paused' : 'video-player__play-button--playing']),
                  onClick: $setup.togglePlay
                }, null, 2 /* CLASS */),
                _createElementVNode("div", {
                  ref: "volume_controls",
                  class: "video-player__volume-controls",
                  onMouseover: $setup.showVolumeBar,
                  onMouseleave: _cache[3] || (_cache[3] = $event => ($setup.show_volume_bar = false)),
                  style: _normalizeStyle($setup.volume_controls_style)
                }, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["video-player__volume-button", $setup.muted || $setup.volume === 0 ? 'video-player__volume-button--muted' : 'video-player__volume-button--not-muted']),
                    onClick: $setup.toggleMute
                  }, null, 2 /* CLASS */),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("input", {
                      type: "range",
                      min: "0",
                      max: "100",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.volume) = $event)),
                      class: "video-player__volume-bar",
                      onInput: $setup.updatePlayerVolume
                    }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
                      [_vModelText, $setup.volume]
                    ]),
                    _createElementVNode("div", {
                      class: "video-player__volume-level",
                      style: _normalizeStyle($setup.volume_level_style)
                    }, null, 4 /* STYLE */)
                  ])
                ], 36 /* STYLE, HYDRATE_EVENTS */)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("button", {
                  class: "video-player__fullscreen-button",
                  onClick: _cache[4] || (_cache[4] = $event => ($setup.video_player.requestFullscreen()))
                })
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    (!$props.commentingDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("button", {
            class: "button button--fill button--icon icon icon--clock",
            onClick: $setup.leaveComment
          }, _toDisplayString($setup.formatTimestamp( $setup.current_time )) + "     Leave a comment here ", 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}