
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading" />
			<div
				v-else-if="asset"
				class="revision-browser"
			>
				<div class="revision-browser__header">
					<div class="revision-browser__title-wrapper">
						<h1 class="h1 mb-32">
							{{ asset.title }}
						</h1>

						<button
							v-if="project.project_status !== 'completed' && user_is_staff"
							class="button mt-8"
							@click="show_upload_modal = !show_upload_modal"
						>
							Add media
						</button>
					</div>

					<div class="revision-browser__toolbar">
						<div class="revision-browser__pips-container">
							<div class="revision-browser__revision-pips">
								<p class="revision-browser__pips-label">
									Stage
								</p>
								<template v-if="contentful_revisions.length">
									<button
										v-for="revision in contentful_revisions"
										:key="'revision-pip-' + revision.id"
										class="revision-browser__revision-pip"
										:class="[
											{ 'revision-browser__revision-pip--selected': isSelectedRevision( revision ) },
											{ 'revision-browser__revision-pip--active': isActiveRevision( revision ) },
											getRevisionPipClass( revision )
										]"
										@mouseover="mouseOverRevision( revision )"
										@mouseleave="visible_revision = null"
										@click="selectRevision( revision )"
									>
										<span>
											{{ revision.revision_number }}
										</span>
										<span v-if="final_revision && final_revision.id === revision.id">
											C
										</span>
									</button>
								</template>

								<button
									v-for="stage in future_stages"
									:key="stage"
									:disabled="true"
									class="revision-browser__revision-pip revision-browser__revision-pip--future-stage"
								>
									<span>
										{{ stage }}
									</span>
								</button>

								<!-- To be added in the future -->
								<!-- <div class="revision-browser__info">
									<div
										class="revision-browser__info-icon"
										@mouseover="show_info_text = true"
										@mouseleave="show_info_text = false"
									/>
									<p
										class="revision-browser__info-text"
										:class="{ 'revision-browser__info-text--reveal': show_info_text }"
									>
										{{ info_text }}
									</p>
								</div> -->
							</div>
							<div
								class="revision-browser__options-wrapper"
								:class="{ 'revision-browser__options-wrapper--hide-options': selected_revision && selected_revision.revision_options.length < 2 }"
							>
								<div
									v-if="selected_revision"
									class="revision-browser__option-pips"
									:tabindex="selected_revision.revision_options.length < 2 ? -1 : 0"
								>
									<p class="revision-browser__pips-label">
										Option
									</p>
									<button
										v-for="option in selected_revision.revision_options"
										:key="'revision-option-pip-' + option.id"
										class="revision-browser__option-pip"
										:class="[
											{ 'revision-browser__option-pip--selected': isSelectedOption( option ) },
											{ 'revision-browser__option-pip--active': isActiveOption( option ) },
											{ 'revision-browser__option-pip--approved': isClientApprovedOption( option ) },
											{ 'revision-browser__option-pip--unapproved': isStaffUnapprovedOption( option ) },
										]"
										@mouseover="mouseOverOption( option )"
										@mouseleave="visible_option = null"
										@click="selectOption( option )"
									>
										<span>
											{{ option.option_number }}
										</span>
									</button>
								</div>

								<template v-if="show_database_button">
									<div class="revision-browser__feature-in-database">
										<button
											class="button button--smaller"
											@click="show_database_modal = true"
										>
											{{ feature_in_database_label }}
										</button>
									</div>

									<div class="revision-browser__published-toggle">
										<LightswitchComponent
											label="Published"
											off-label="Published"
											:checked="selected_option.published"
											:disabled="loading"
											@change="value => toggleSelectedOptionAsPublished( value )"
										/>
									</div>

									<Teleport to="#modal">
										<Transition name="fade">
											<DatabaseModalComponent
												v-if="show_database_modal"
												:option="selected_option"
												:project="project"
												:base-asset-type="base_asset_type"
												:mode="selected_option.include_in_database ? 'remove' : 'add'"
												@confirm="toggleInclusionInDatabaseOption"
												@close-modal="show_database_modal = false"
												@close-modal-and-refresh-data="closeDatabaseModalAndRefreshData"
											/>
										</Transition>
									</Teleport>
								</template>
							</div>
						</div>

						<div class="revision-browser__actions">
							<template v-if="selected_option">
								<div
									v-if="project.project_status !== 'completed' && ( user_is_staff && !option_is_staff_approved )"
									class="revision-browser__approve-button-wrapper"
									:class="{ 'revision-browser__approve-button-wrapper--show-permission-warning': !user_has_staff_approval_permission }"
								>
									<button
										class="revision-browser__approve-button"
										:class="[
											{ 'revision-browser__approve-button--loading': loading_approval }
										]"
										:disabled="!user_has_staff_approval_permission"
										@click="show_approval_modal = true"
									>
										{{ approval_title }}
									</button>
									<div class="revision-browser__permission-warning">
										You do not have the required permissions to approve this image.
									</div>
								</div>
								<a
									v-else-if="user_is_client && final_revision && ( selected_revision.id === final_revision.id && selected_revision.revision_number === final_revision.revision_number )"
									class="revision-browser__download-button"
									:href="selected_option_download_url"
								>
									Download final {{ selected_option.video_url ? 'video' : 'image' }}
								</a>
								<button
									v-else-if="project.project_status !== 'completed' && ( user_is_client && ( !final_revision || option_is_client_approved ) || ( user_has_client_approval_permission && !final_revision && stage_is_b && stage_is_last ) )"
									class="revision-browser__approve-button"
									:class="[
										{ 'revision-browser__approve-button--loading': loading_approval },
										{ 'revision-browser__approve-button--approved': option_is_client_approved }
									]"
									:disabled="option_is_client_approved || !user_has_client_approval_permission"
									@click="show_approval_modal = true"
								>
									{{ approval_title }}
								</button>
							</template>

							<Teleport to="#modal">
								<Transition name="fade">
									<ApprovalModalComponent
										v-if="show_approval_modal"
										:type="approval_type"
										:revision="selected_revision"
										:option="selected_option"
										@close-modal="show_approval_modal = false"
										@approve-final-revision="approveSelectedRevision"
										@approve="approveSelectedOption"
									/>
								</Transition>
							</Teleport>

							<div class="revision-browser__asset-switch">
								<div class="revision-browser__asset-switch-controls">
									<button
										class="revision-browser__asset-switch-button--prev"
										:class="{ 'revision-browser__asset-switch-button--disabled': !can_navigate_to_prev_asset }"
										@click="goToPrevAsset"
									/>
									<button
										class="revision-browser__asset-switch-button--next"
										:class="{ 'revision-browser__asset-switch-button--disabled': !can_navigate_to_next_asset }"
										@click="goToNextAsset"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="selected_option"
					class="revision-browser__approval-banner-wrapper"
				>
					<Transition name="banner">
						<div
							v-if="asset.asset_status && ['paused', 'cancelled'].includes( asset.asset_status )"
							class="revision-browser__approval-banner"
						>
							This asset has been {{ asset.asset_status }}
						</div>
						<div
							v-else-if="show_approved_banner"
							ref="banner"
							class="revision-browser__approval-banner revision-browser__approval-banner--approved"
						>
							Approved
						</div>
						<div
							v-else-if="show_unapproved_banner"
							ref="banner"
							class="revision-browser__approval-banner"
						>
							<template v-if="selected_revision.revision_options.length > 1">
								Greyed out options are not visible to client. {{ user_has_staff_approval_permission ? 'Please approve any unapproved options.' : 'Awaiting approval.' }}
							</template>
							<template v-else>
								Upload not visible to client. {{ user_has_staff_approval_permission ? 'Please approve upload.' : 'Awaiting approval.' }}
							</template>
						</div>
					</Transition>
				</div>

				<Transition name="fade">
					<div
						v-if="active_option"
						ref="revision-image-container"
						class="revision-browser__revision"
						:class="{ 'revision-browser__revision--with-banner': show_approved_banner || show_unapproved_banner }"
						@mousemove="setCursorHoverStyles"
					>
						<template
							v-if="active_option.image_large_url"
						>
							<img
								ref="revision-image"
								:key="`image-${active_option.id}`"
								class="revision-browser__option revision-browser__option--image"
								:src="active_option.image_large_url"
								:alt="active_option.title"
								@mouseenter="show_cursor = true"
								@mouseleave="show_cursor = false"
								@click="openGallery"
							>

							<span
								class="revision-browser__fullscreen-cursor"
								:class="{ 'revision-browser__fullscreen-cursor--show': show_cursor }"
								:style="cursor_styles"
							/>
						</template>

						<div
							v-else-if="active_option.video_url"
							class="revision-browser__option"
						>
							<VideoOptionComponent
								:key="active_option.id"
								:option="active_option"
								:commenting-disabled="project.project_status === 'completed' || !!final_revision || !stage_is_last"
							/>
						</div>

						<div
							v-else-if="active_option.tour_id"
							:key="`explore-${active_option.id}`"
							class="iframe-container"
						>
							<iframe
								id="embed"
								class="tour"
								:src="`https://www.viewform.co/tours/${active_option.tour_id}`"
								frameborder="0"
							/>
						</div>
					</div>

					<div
						v-else
						class="revision-browser__revision revision-browser__revision--default"
					>
						<div class="revision-browser__option revision-browser__option--default" />
					</div>
				</Transition>
			</div>
		</Transition>
	</div>

	<UploadModalComponent
		v-if="project"
		:show="show_upload_modal"
		:project="project"
		@close-modal="closeUploadModal"
	/>

	<Teleport to="#modal">
		<Transition name="fade">
			<GalleryModalComponent
				v-if="gallery_images && gallery_images.length && show_gallery_modal"
				:options="gallery_images"
				@close-modal="closeGalleryModal"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import ApprovalModalComponent from '../components/modals/ApprovalModalComponent.vue';
import DatabaseModalComponent from '../components/modals/DatabaseModalComponent.vue';
import GalleryModalComponent from '../components/modals/GalleryModalComponent.vue';
import LightswitchComponent from '../components/LightswitchComponent.vue';
import PageLoaderComponent from '../components/PageLoaderComponent.vue';
import UploadModalComponent from '../components/uploads/UploadModalComponent.vue';
import VideoOptionComponent from '../components/VideoOptionComponent.vue';

import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { updatePageTitle } from '../../helpers';

import {
	USER_GROUP_STAFF,
	USER_GROUP_CLIENT,
	USER_GROUP_FREELANCER
} from '../constants';

export default {
	components: {
		ApprovalModalComponent,
		DatabaseModalComponent,
		GalleryModalComponent,
		LightswitchComponent,
		PageLoaderComponent,
		UploadModalComponent,
		VideoOptionComponent
	},
	emits: [ 'option-approved' ],
	setup() {
		const data_store = useDataStore();
		const { approval_globals, user_profile } = storeToRefs( data_store );
		const {
			user_group,
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			getAssetById,
			getProjectById,
			getApprovalGlobals,
			staffApproveOption,
			clientApproveOption,
			clientApproveFinalRevision,
			toggleIncludeInDatabaseOption,
			togglePublishedOption,
			setBreadcrumbs
		} = data_store;
		return {
			user_group,
			approval_globals,
			user_profile,
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			getAssetById,
			getProjectById,
			getApprovalGlobals,
			staffApproveOption,
			clientApproveOption,
			clientApproveFinalRevision,
			toggleIncludeInDatabaseOption,
			togglePublishedOption,
			setBreadcrumbs
		};
	},
	data() {
		return {
			loading: false,
			loading_approval: false,
			loading_download: false,
			asset: null,
			project: null,
			user_is_staff_admin: false,
			user_is_staff_super_admin: false,
			selected_revision: null,
			visible_revision: null,
			selected_option: null,
			visible_option: null,
			show_info_text: false,
			upload_modal_key: 'upload-modal-1',
			show_approved_banner: false,
			show_upload_modal: false,
			show_approval_modal: false,
			show_database_modal: false,
			show_gallery_modal: false,
			gallery_images: null,
			show_cursor: false,
			cursor_coordinates: {
				x: 0,
				y: 0
			},
		};
	},
	computed: {
		user_is_client() {
			return this.user_group === USER_GROUP_CLIENT;
		},
		user_is_freelancer() {
			return this.user_group === USER_GROUP_FREELANCER;
		},
		user_is_staff() {
			return this.user_group === USER_GROUP_STAFF;
		},
		user_has_client_permissions() {
			return this.user_is_client || this.user_has_client_approval_permission;
		},
		base_asset_type() {
			if ( !this.asset.media_type || !this.asset.media_type.length ) {
				return '';
			}
			return this.asset.media_type[0].base_type;
		},
		final_revision() {
			if ( this.asset && this.asset.final_revision && this.asset.final_revision.length ) {
				return this.asset.asset_revisions.find( revision => this.asset.final_revision[0].id === revision.id );
			}
			return null;
		},
		active_option() {
			if ( this.visible_revision && this.selected_revision.id !== this.visible_revision.id ) {
				return this.visible_revision.revision_options[0];
			}
			return this.visible_option || this.selected_option;
		},
		contentful_revisions() {
			if ( !this.asset.asset_revisions || !this.asset.asset_revisions.length ) {
				return [];
			}
			return this.asset.asset_revisions.filter( revision => revision.revision_options.length );
		},
		future_stages() {
			if ( !this.asset ) {
				return null;
			}
			if ( this.final_revision ) {
				return null;
			}
			if ( this.contentful_revisions.find( revision => revision.revision_number[0] === 'B' ) ) {
				return [ 'C' ];
			}
			if ( this.contentful_revisions.find( revision => revision.revision_number[0] === 'A' ) ) {
				return [ 'B', 'C' ];
			}
			return [ 'A', 'B', 'C' ];
		},
		info_text() {
			return 'Include info text here.';
		},
		can_navigate_to_prev_asset() {
			if ( !this.project || !this.asset || !this.project.project_assets || !this.project.project_assets.length ) {
				return false;
			}
			return this.project.project_assets[0].id !== this.asset.id;
		},
		can_navigate_to_next_asset() {
			if ( !this.project || !this.asset || !this.project.project_assets || !this.project.project_assets.length ) {
				return false;
			}
			return this.project.project_assets[ this.project.project_assets.length - 1 ].id !== this.asset.id;
		},
		stage_is_b() {
			return this.selected_revision && this.selected_revision.revision_number[0].toUpperCase() === 'B';
		},
		stage_is_last() {
			return this.selected_revision && this.selected_revision.revision_number === this.asset.asset_revisions.at( -1 ).revision_number;
		},
		approval_title() {
			if ( !this.selected_option ) {
				return 'Approve';
			}
			if ( this.user_is_staff && !this.option_is_staff_approved ) {
				return 'Approve upload';
			}
			if ( this.user_is_client || ( this.selected_revision.revision_number[0] === 'B' && this.user_has_client_approval_permission ) ) {
				return this.getApprovalStageTitle();
			}
			return 'Upload approved';
		},
		approval_type() {
			if ( !this.option_is_staff_approved ) {
				return USER_GROUP_STAFF;
			}
			if ( this.user_is_client || this.user_has_client_approval_permission ) {
				return USER_GROUP_CLIENT;
			}
		},
		option_is_staff_approved() {
			return this.selected_option && this.selected_option.staff_approval_user.length;
		},
		revision_is_fully_staff_approved() {
			return this.selected_revision && !this.selected_revision.revision_options.find( option => option.staff_approval_user.length < 1 );
		},
		option_is_client_approved() {
			return this.selected_option && this.selected_option.client_approval_user.length;
		},
		user_has_staff_approval_permission() {
			if ( !this.project || !this.approval_globals ) {
				return false;
			}
			return [ ...this.project.staff_approvers, ...this.approval_globals.global_approvers ]
				.map( user => parseInt( user.id ) )
				.includes( parseInt( this.user_profile.id ) );
		},
		user_has_client_approval_permission() {
			if ( !this.project ) {
				return false;
			}
			return this.project.client_approvers
				.map( user => parseInt( user.id ) )
				.includes( parseInt( this.user_profile.id ) );
		},
		selected_stage_needs_client_approval() {
			return !this.selectedStageHasBeenClientApproved();
		},
		show_unapproved_banner() {
			if ( this.selected_revision.revision_options.length > 1 ) {
				return !this.revision_is_fully_staff_approved && this.user_is_staff;
			}
			return !this.option_is_staff_approved && this.user_is_staff;
		},
		show_database_button() {
			if ( !this.final_revision || !this.selected_revision || ( !this.user_is_staff_admin && !this.user_is_staff_super_admin ) ) {
				return false;
			}
			return this.selected_revision.id === this.final_revision.id;
		},
		feature_in_database_label() {
			if ( this.selected_option && this.selected_option.include_in_database ) {
				return 'Remove from database';
			}
			return 'Feature in database';
		},
		cursor_styles() {
			return {
				transform: `translateX( ${this.cursor_coordinates.x} ) translateY( ${this.cursor_coordinates.y} )`
			};
		},
		selected_option_download_url() {
			let option_url = '';
			if ( this.selected_option.image_original_url ) {
				option_url = this.selected_option.image_original_url;
			} else if ( this.selected_option.video_url ) {
				option_url = this.selected_option.video_url;
			};
			const escaped_url = encodeURIComponent(
				option_url
			);
			return `/download-option?url=${escaped_url}`;
		}
	},
	watch: {
		selected_option() {
			this.show_approved_banner = false;
		}
	},
	async mounted() {
		this.loading = true;

		await this.getProject();
		updatePageTitle( this.project.title );

		await this.getAsset();

		this.updateBreadcrumbs();

		if ( !this.approval_globals.global_approvers || !this.approval_globals.global_approvers.length ) {
			await this.getApproverGlobals();
		}

		this.user_is_staff_admin = await this.isUserStaffAdmin();
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();

		this.loading = false;
	},
	async updated() {
		if ( !this.loading && this.$route.query && this.$route.query.refresh ) {
			this.loading = true;
			await this.getAsset();
			this.loading = false;
		}
	},
	methods: {
		async getProject() {
			try {
				const response = await this.getProjectById( this.$route.params.project_id );
				if ( response.data ) {
					this.project = response.data.entry;
				}
			} catch ( error ) {
				// console.log( error );
			}
		},
		async getAsset() {
			try {
				const response = await this.getAssetById( this.$route.params.asset_id );
				if ( response.data ) {
					this.asset = response.data.entry;
					this.asset.asset_revisions.sort( ( a, b ) => {
						if ( a.revision_number[0] < b.revision_number[0] ) {
							return -1;
						}
						if ( a.revision_number[0] > b.revision_number[0] ) {
							return 1;
						}
						return parseInt( a.revision_number.slice( 1 ) ) - parseInt( b.revision_number.slice( 1 ) );
					} );
					this.asset.asset_revisions.forEach( ( revision, i ) => {
						if ( this.asset.media_type[0].base_type !== 'explore' ) {
							this.asset.asset_revisions[i].revision_options.sort( ( a, b ) => parseInt( a.option_number ) - parseInt( b.option_number ) );
						}
					} );

					if ( this.$route.query.revision && this.$route.query.option ) {
						const revision = this.asset.asset_revisions.find( revision => {
							return parseInt( revision.id ) === parseInt( this.$route.query.revision );
						} ) || this.asset.asset_revisions[0] || null;

						if ( !revision ) {
							this.updateQueryString();
							return;
						}
						const option = revision.revision_options.find( option => {
							return parseInt( option.id ) === parseInt( this.$route.query.option );
						} ) || revision.revision_options[0] || null;

						this.selected_revision = revision;
						this.selected_option = option;

						if ( !this.selected_option || parseInt( this.$route.query.revision ) !== parseInt( this.selected_revision.id ) || parseInt( this.$route.query.option ) !== parseInt( this.selected_option.id ) ) {
							this.updateQueryString();
						}
					} else {
						this.selected_revision = this.asset.asset_revisions[ this.asset.asset_revisions.length - 1 ] || null;
						this.selected_option = this.selected_revision ? this.selected_revision.revision_options[0] || null : null;
						this.updateQueryString();
					}
				}
			} catch ( error ) {
				// console.log( error );
			}
		},
		updateBreadcrumbs() {
			const breadcrumbs = [ { label: 'Projects', route: { name: 'projects' }, icon: 'briefcase' } ];

			if ( this.project ) {
				breadcrumbs.push(
					{
						label: this.project.title,
						route: {
							name: 'projects__project',
							params: {
								project_id: this.project.id
							}
						}
					},
					{
						label: 'Grid',
						route: {
							name: 'projects__project',
							params: {
								project_id: this.project.id
							},
							query: {
								tab: 'grid'
							}
						}
					},
				);

				if ( this.asset ) {
					breadcrumbs.push( { label: this.asset.title } );
				}
			}

			this.setBreadcrumbs( breadcrumbs );
		},
		async goToPrevAsset() {
			const asset_ids = this.project.project_assets.map( asset => parseInt( asset.id ) );
			const prev_id = asset_ids[ asset_ids.indexOf( parseInt( this.$route.params.asset_id ) ) - 1 ];

			if ( prev_id ) {
				this.loading = true;
				await this.$router.replace( {
					name: 'projects__project__asset',
					params: {
						project_id: this.project.id,
						asset_id: prev_id
					},
					query: {}
				} );
				await this.getAsset();

				this.updateBreadcrumbs();
				this.loading = false;
			}
		},
		async goToNextAsset() {
			const asset_ids = this.project.project_assets.map( asset => parseInt( asset.id ) );
			const next_id = asset_ids[ asset_ids.indexOf( parseInt( this.$route.params.asset_id ) ) + 1 ];

			if ( next_id ) {
				this.loading = true;
				await this.$router.replace( {
					name: 'projects__project__asset',
					params: {
						project_id: this.project.id,
						asset_id: next_id
					},
					query: {}
				} );
				await this.getAsset();

				this.updateBreadcrumbs();
				this.loading = false;
			}
		},
		async approveSelectedOption() {
			this.show_approval_modal = false;
			this.loading_approval = true;
			if ( this.user_is_staff ) {
				try {
					const response = await this.staffApproveOption( parseInt( this.selected_option.id ) );
					if ( response && response.data.createOptionStaffApproval ) {
						await this.getAsset();
						this.show_approved_banner = true;
						this.$emit( 'option-approved' );
					}
				} catch ( error ) {
					// console.log( error );
				}
			}
			if ( this.user_is_client ) {
				try {
					const response = await this.clientApproveOption( parseInt( this.selected_option.id ) );
					if ( response && response.data.createOptionClientApproval ) {
						await this.getAsset();
						this.show_approved_banner = true;
						this.$emit( 'option-approved' );
					}
				} catch ( error ) {
					// console.log( error );
				}
			}
			this.loading_approval = false;
		},
		async approveSelectedRevision() {
			this.show_approval_modal = false;
			this.loading_approval = true;
			try {
				const response = await this.clientApproveFinalRevision( parseInt( this.selected_revision.id ) );
				if ( response && response.data.createFinalRevisionClientApproval ) {
					await this.getAsset();
					this.show_approved_banner = true;
					this.$emit( 'revision-approved' );
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_approval = false;
		},
		async unapproveSelectedRevision() {
			this.show_approval_modal = false;
			this.loading_approval = true;
			try {
				const response = await this.staffUnapproveFinalRevision( parseInt( this.selected_revision.id ) );
				if ( response && response.data.removeFinalRevisionClientApproval ) {
					await this.getAsset();
					this.$emit( 'revision-approved' );
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_approval = false;
		},
		isActiveRevision( revision ) {
			if ( this.visible_revision ) {
				return this.visible_revision.id === revision.id && this.visible_revision.revision_number === revision.revision_number;
			}
			return this.isSelectedRevision( revision );
		},
		isSelectedRevision( revision ) {
			return this.selected_revision.id === revision.id && this.selected_revision.revision_number === revision.revision_number;
		},
		isActiveOption( option ) {
			if ( this.visible_option ) {
				return this.visible_option.id === option.id && this.visible_option.option_number === option.option_number;
			}
			return this.isSelectedOption( option );
		},
		isSelectedOption( option ) {
			return this.selected_option.id === option.id && this.selected_option.option_number === option.option_number;
		},
		isStaffUnapprovedOption( option ) {
			return option.staff_approval_user.length < 1;
		},
		isClientApprovedOption( option ) {
			return option.client_approval_user.length > 0;
		},
		getRevisionPipClass( revision ) {
			const has_only_unapproved_options = revision.revision_options.every( option => option.staff_approval_user.length < 1 );

			if ( has_only_unapproved_options ) {
				return 'revision-browser__revision-pip--unapproved';
			}
			if ( this.final_revision && this.final_revision.id == revision.id ) {
				return 'revision-browser__revision-pip--stage-final';
			}

			const first_char = revision.revision_number[0];

			switch ( first_char ) {
				case 'A':
					return 'revision-browser__revision-pip--stage-a';
				case 'B':
					return 'revision-browser__revision-pip--stage-b';
				case 'C':
					return 'revision-browser__revision-pip--stage-c';
			}
		},
		mouseOverRevision( revision ) {
			if ( this.asset.media_type[0].base_type === 'image' ) {
				this.visible_revision = revision;
			}
		},
		mouseOverOption( option ) {
			if ( this.asset.media_type[0].base_type === 'image' ) {
				this.visible_option = option;
			}
		},
		selectRevision( revision ) {
			this.show_dashboard = false;
			this.selected_revision = revision;
			this.selected_option = this.selected_revision.revision_options[0] || null;
			this.updateQueryString();
		},
		selectOption( option ) {
			this.show_dashboard = false;
			this.selected_option = option;
			this.updateQueryString();
		},
		getApprovalStageTitle() {
			const title_array = this.selected_option.title.split( ' - ' );
			if ( this.selected_revision.revision_number[0].toUpperCase() === 'B' ) {
				if ( this.user_is_client ) {
					return `Approve Stage ${ title_array.at( -2 )}`;
				}
				if ( this.user_is_staff_admin || this.user_is_staff_super_admin ) {
					return 'Set as Stage C';
				}
			}
			if ( this.selected_revision.revision_options.length < 2 ) {
				return `Approve Stage ${ title_array.at( -2 )[0]}`;
			}
			return `Approve Stage ${ title_array.at( -2 )[0]} - Option ${ title_array.at( -1 ) }`;
		},
		setCursorHoverStyles( e ) {
			if ( this.base_asset_type !== 'image' ) {
				return;
			}
			const image_width = this.$refs['revision-image'].offsetWidth;
			const image_height = this.$refs['revision-image'].offsetHeight;

			// Setting position of cursor relative to image
			const image_rect = this.$refs['revision-image'].getBoundingClientRect();
			const x_fraction_of_image = ( ( e.clientX - image_rect.left ) / image_width );
			const y_fraction_of_image = ( ( e.clientY - image_rect.top ) / image_height );

			const x_cursor_location = x_fraction_of_image * image_width;
			const y_cursor_location = y_fraction_of_image * image_height;

			this.cursor_coordinates = {
				x: `${x_cursor_location}px`,
				y: `${y_cursor_location}px`
			};
		},
		openGallery() {
			this.gallery_images = [ ...this.selected_revision.revision_options ]
				.filter( option => option.image_large_url )
				.map( ( option ) => {
					return {
						title: option.title,
						image_url: option.image_large_url,
						selected: option.id === this.selected_option.id
					};
				} )
			;
			this.show_gallery_modal = true;
		},
		closeGalleryModal() {
			this.show_gallery_modal = false;
			this.gallery_images = null;
		},
		updateQueryString() {
			const query = {};
			if ( this.selected_revision ) {
				query.revision = this.selected_revision.id;
			}
			if ( this.selected_option ) {
				query.option = this.selected_option.id;
			}

			this.$router.replace( {
				name: 'projects__project__asset',
				params: {
					project_id: this.project.id,
					asset_id: this.asset.id
				},
				query
			} );
		},
		closeUploadModal() {
			this.show_upload_modal = false;
		},
		async closeDatabaseModalAndRefreshData() {
			this.show_database_modal = false;
			this.loading = true;
			await this.getAsset();
			this.loading = false;
		},
		async toggleInclusionInDatabaseOption() {
			const response = await this.toggleIncludeInDatabaseOption( {
				option_id: parseInt( this.selected_option.id ),
				project_id: parseInt( this.project.id ),
				include_in_database: !this.selected_option.include_in_database,
				asset_type: this.base_asset_type
			} );
			this.show_database_modal = false;

			if ( response.data ) {
				const option_entry = response.data[`save_revision_options_${this.base_asset_type}_Entry`];
				if ( !option_entry ) {
					return;
				}

				const option_id = option_entry.id;
				const include_in_database = option_entry.include_in_database;

				const indexes = this.getRevisionAndOptionIndexesByOptionId( option_id );
				if ( !indexes ) {
					return;
				}

				this.asset.asset_revisions[ indexes.revision_index ].revision_options[ indexes.option_index ].include_in_database = include_in_database;
			}
		},
		async toggleSelectedOptionAsPublished( value ) {
			const response = await this.togglePublishedOption( {
				option_id: this.selected_option.id,
				published: value,
				asset_type: this.base_asset_type
			} );

			if ( response.data ) {
				const option_entry = response.data[`save_revision_options_${this.base_asset_type}_Entry`];
				if ( !option_entry ) {
					return;
				}
				const option_id = option_entry.id;
				const published = option_entry.published;

				const indexes = this.getRevisionAndOptionIndexesByOptionId( option_id );
				if ( !indexes ) {
					return;
				}

				this.asset.asset_revisions[ indexes.revision_index ].revision_options[ indexes.option_index ].published = published;
			}
		},
		getRevisionAndOptionIndexesByOptionId( option_id ) {
			const revision_index = this.asset.asset_revisions.findIndex( revision => revision.revision_options.some( option => option.id === option_id ) );
			if ( revision_index === -1 ) {
				return null;
			}

			const option_index = this.asset.asset_revisions[ revision_index ].revision_options.findIndex( option => option.id === option_id );
			if ( option_index === -1 ) {
				return null;
			}

			return {
				revision_index: revision_index,
				option_index: option_index
			}
		}
	}
};

</script>
