<template>
	<div class="asset-status-modal-wrapper">
		<div class="asset-status-modal">
			<button
				class="asset-status-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="asset-status-modal__content">
				<h2 class="asset-status-modal__heading">
					Asset Status
				</h2>

				<fieldset class="form__radiogroup mb-24">
					<legend class="invisible">Asset Status</legend>
					<label
						for="live"
						class="form__radio"
					>
						<input
							type="radio"
							id="live"
							name="asset-status"
							value="live"
							v-model="status"
						/>
						Live
					</label>
					<label
						for="paused"
						class="form__radio"
					>
						<input
							type="radio"
							id="paused"
							name="asset-status"
							value="paused"
							v-model="status"
						/>
						Paused
					</label>
					<label
						for="completed"
						class="form__radio"
					>
						<input
							type="radio"
							id="cancelled"
							name="asset-status"
							value="cancelled"
							v-model="status"
						/>
						Cancelled
					</label>
				</fieldset>

				<div class="asset-status-modal__buttons mb-16">
					<button
						class="button"
						:disabled="loading"
						@click="closeModal"
					>
						Cancel
					</button>
					<button
						class="button button--fill"
						:class="{ 'button--loading' : loading }"
						:disabled="loading"
						@click="updateAssetStatus"
					>
						Save
					</button>
				</div>

				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue';
import { useDataStore } from '../../stores/data';

const emit = defineEmits( ['status-updated', 'close-modal'] );
const { asset } = defineProps( {
 	asset: { required: true, type: Object }
} );

const { setAssetStatus } = useDataStore();

const loading = ref( false );
const status = ref( 'live' );
const error_message = ref( null );

onMounted( () => {
	status.value = asset.asset_status;
} )

function closeModal() {
	emit( 'close-modal' );
};

async function updateAssetStatus() {
	error_message.value = null;
	loading.value = true;

	if ( !status ) {
		this.error_message = 'Please select an option';
		this.loading = false;
		return;
	}

	try {
		const response = await setAssetStatus( {
			asset_id: asset.id,
			asset_status: status.value
		} );
		if ( response && response.data ) {
			emit( 'status-updated' );
		}
	} catch ( error ) {
		this.error_message = error;
		this.loading = false;
	}
};
</script>