
<template>
	<NavSidebarComponent
		:show-nav="show_nav"
	/>
	<main id="main-content">
		<div
			id="page"
			:class="[ 'page', { 'page--no-padding' : is_login_route } ]"
		>
			<div class="container">
				<BreadcrumbsComponent
					v-if="!is_login_route"
				/>
			</div>
			<router-view v-slot="{ Component }">
				<Transition name="fade">
					<PageLoaderComponent
						v-if="loading"
					/>
					<component :is="Component"
						v-else
						@logged-in="fetchInitialData"
						@option-approved="showApprovalsSidebar"
						@revision-approved="showApprovalsSidebar"
						@leave-comment-at-timestamp="startNewCommentsAtTimestamp"
					/>
				</Transition>
			</router-view>
		</div>
		<SidebarWrapperComponent
			v-show="!is_login_route"
			ref="sidebar-wrapper"
		/>
		<AlertBannerComponent>
			<template #content>
				<div id="alert-banner-content" />
			</template>
		</AlertBannerComponent>
		<div
			id="modal"
			class="modal-container"
		/>
	</main>
</template>

<script>
import SidebarWrapperComponent from './components/sidebars/SidebarWrapperComponent.vue';
import NavSidebarComponent from './components/sidebars/NavSidebarComponent.vue';
import BreadcrumbsComponent from './components/BreadcrumbsComponent.vue';
import AlertBannerComponent from './components/AlertBannerComponent.vue';
import PageLoaderComponent from './components/PageLoaderComponent.vue';
import { useDataStore } from './stores/data';
import { storeToRefs } from 'pinia';
import { USER_GROUP_STAFF } from './constants';
import { provide, ref } from 'vue';

export default {
	components: {
		BreadcrumbsComponent,
		NavSidebarComponent,
		SidebarWrapperComponent,
		AlertBannerComponent,
		PageLoaderComponent
	},
	setup() {
		// Timestamp for leaving comments on videos
		// Gets updated from the video component, and read out in the comment form
		const comment_timestamp = ref( null );

		const updateCommentTimestamp = updated_timestamp => {
			comment_timestamp.value = updated_timestamp
		}

		provide( 'comment-timestamp', {
			comment_timestamp,
			updateCommentTimestamp
		} );

		// Variable used for jumping to specific frame in video
		// Gets updated from a comment component, and read out in the video component
		const video_frame = ref( null );

		const updateVideoFrame = updated_frame => {
			video_frame.value = updated_frame
		}

		provide( 'video-frame', {
			video_frame,
			updateVideoFrame
		} );

		const data_store = useDataStore();
		const {
			user_data,
			user_profile,
			user_group,
		} = storeToRefs( data_store );
		const {
			getApprovalGlobals,
			getMediaTypes,
			getAllNotifications,
			getUserRelatedNotificationsCount,
			getUserProfile,
		} = data_store;
		return {
			comment_timestamp,
			updateCommentTimestamp,
			video_frame,
			updateVideoFrame,
			user_data,
			user_profile,
			user_group,
			getApprovalGlobals,
			getMediaTypes,
			getAllNotifications,
			getUserRelatedNotificationsCount,
			getUserProfile,
		};
	},
	data() {
		return {
			loading: true
		};
	},
	computed: {
		is_login_route() {
			return this.$route.name === 'login' || this.$route.name === 'request-password-reset';
		},
		show_nav() {
			return this.user_data !== null && !this.is_login_route;
		}
	},
	watch: {
		$route: {
			handler( new_value, old_value ) {
				if ( !this.is_login_route ) {
					this.getUserRelatedNotificationsCount();
				}
				if ( new_value.name === 'login' || ( old_value !== new_value && old_value.name === 'login' ) ) {
					this.login_key++;
				}
			}
		}, deep: true
	},
	mounted() {
		if ( this.$route.name === 'request-password-reset' ) {
			return;
		}
		this.fetchInitialData();
	},
	methods: {
		async fetchInitialData() {
			this.loading = true;
			await this.getUserRelatedNotificationsCount();
			await this.getUserProfile();
			await this.getApprovalGlobals();
			await this.getMediaTypes();
			this.loading = false;
		},
		showApprovalsSidebar() {
			this.$refs[ 'sidebar-wrapper' ].showApprovals();
		}
	}
};
</script>
