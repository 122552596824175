<template>
	<TransitionGroup
		name="list"
		tag="ul"
		class="comments"
		:class="{ 'comments--discussion': commentType === 'discussion' }"
	>
		<li
			v-for="comment in comments"
			:key="comment.id"
			class="comments__comment"
		>
			<div
				v-if="commentType === 'discussion'"
				class="comments__profile-picture-container"
			>
				<ProfilePictureComponent :user="comment.author" />
			</div>
			<div class="comments__comment-container">
				<div class="comments__comment-metadata">
					<p class="comments__comment-name">
						{{ comment.author.fullName }}
					</p>
					<p class="comments__comment-time">
						{{ getTimepassedLabel( comment.postDate ) }}
					</p>
				</div>
				<button
					v-if="showTimestamps && comment.video_timestamp"
					class="comment__timestamp"
					@click="flashVideoFrame( comment.video_timestamp )"
				>
					{{ formatTimestamp( comment.video_timestamp ) }}
				</button>
				<!-- eslint-disable vue/no-v-html -->
				<div
					class="comments__comment-content typeset typeset--small"
					v-html="comment.comment_content"
				/>
				<div
					v-if="getImages( comment.comment_attachments ).length"
					class="comments__image-attachments"
				>
					<button
						v-for="attachment, index in getImages( comment.comment_attachments )"
						:key="`${comment.id}-image-attachment-${index}`"
						class="comments__image-attachment-thumbnail"
						@click="setActiveAttachments( comment.comment_attachments, attachment )"
					>
						<img
							class="comments__image-attachment"
							:src="attachment.attachment_thumbnail_url"
						>
					</button>
				</div>
				<p
					v-if="filesPending( comment ) > 0"
					class="comments__files-pending"
				>
					{{ filesPending( comment ) }} attachment(s) processing
				</p>

				<div
					v-if="comment.comment_attachments.length > 1 || getFiles( comment.comment_attachments ).length"
					class="comments__links"
				>
					<a
						v-for="attachment, index in getFiles( comment.comment_attachments )"
						:key="`${comment.id}-image-attachment-${index}`"
						class="comments__file-attachment"
						:href="attachment.attachment_url"
						target="_blank"
					>
						{{ attachment.attachment_filename }}
					</a>
					<a
						v-if="comment.comment_attachments.length > 1"
						class="comments__file-attachment comments__file-attachment--download-all"
						:class="{ 'comments__file-attachment--loading ': loading_attachments === comment.id }"
						@click="downloadAllAttachments( comment )"
					>
						Download all attachments
					</a>
				</div>
			</div>
		</li>
	</TransitionGroup>
	<div
		v-if="!comments || !comments.length"
		class="comments__comment-content comments__comment-content--no-content typeset typeset--small"
		:class="{ 'comments__comment-content--empty-discussion': commentType === 'discussion' }"
	>
		<p>
			No comments yet.
		</p>
	</div>

	<Teleport to="#modal">
		<Transition name="fade">
			<GalleryModalComponent
				v-if="active_attachments && active_attachments.length && show_attachment_modal"
				:options="active_attachments"
				:config="{ downloadable: true }"
				@close-modal="closeAttachmentModal"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import GalleryModalComponent from './modals/GalleryModalComponent.vue';
import ProfilePictureComponent from './ProfilePictureComponent.vue';

import { useDataStore } from '../stores/data';
import { getTimePassed } from '../../helpers';
import moment from 'moment';
import { inject } from 'vue';

export default {
	components: {
		GalleryModalComponent,
		ProfilePictureComponent
	},
	setup() {
		const { updateVideoFrame } = inject( 'video-frame' );

		const data_store = useDataStore();
		const { createAttachmentsArchive, setAlertMessage } = data_store;

		return { updateVideoFrame, createAttachmentsArchive, setAlertMessage };
	},
	props: {
		comments: { required: true, type: Array },
		commentType: { required: false, type: String, default: 'option' },
		showTimestamps: { required: false, type: Boolean, default: false }
	},
	data() {
		return {
			loading_attachments: null,
			active_attachments: [],
			show_attachment_modal: false
		};
	},
	methods: {
		getTimepassedLabel( date_string ) {
			if ( this.commentType === 'discussion' ) {
				return moment( new Date( date_string ) ).format( 'HH:mm — D MMMM YYYY' );
			}
			return getTimePassed( date_string );
		},
		formatTimestamp( timestamp_in_seconds ) {
			const mins = Math.floor( timestamp_in_seconds / 60 );
			const secs = Math.floor( timestamp_in_seconds % 60 );

			return secs < 10 ? `${mins}:0${secs}` : `${mins}:${secs}`;
		},
		filesPending( comment ) {
			if ( !comment.number_of_files_attached ) {
				return 0;
			}
			const files_pending = comment.number_of_files_attached - comment.comment_attachments.length;
			return files_pending <= 0 ? 0 : files_pending;
		},
		getImages( attachments ) {
			return attachments.filter( attachment => attachment.attachment_type === 'image' && attachment.attachment_thumbnail_url );
		},
		getFiles( attachments ) {
			return attachments.filter( attachment => attachment.attachment_type !== 'image' || !attachment.attachment_thumbnail_url );
		},
		async downloadAllAttachments( comment ) {
			this.loading_attachments = comment.id;
			try {
				const response = await this.createAttachmentsArchive( { comment_id: parseInt( comment.id ) } );

				if ( response && response.errors ) {
					this.setAlertMessage( 'Something went wrong, please try again' );
					return;
				}
				if ( response && response.data ) {
					this.setAlertMessage( "Your download is being prepared, we will email you a link when it's ready" ); // eslint-disable-line quotes
				}
			} catch ( error ) {
				// console.log( error );
			}
			this.loading_attachments = null;
		},
		setActiveAttachments( attachments, selected_attachment ) {
			this.active_attachments = attachments
				.filter( attachment => attachment.attachment_type === 'image' && attachment.attachment_thumbnail_url )
				.map( attachment => {
					return {
						title: attachment.attachment_filename,
						image_url: attachment.attachment_url,
						selected: attachment === selected_attachment
					};
				} );
			this.show_attachment_modal = true;
		},
		closeAttachmentModal() {
			this.show_attachment_modal = false;
		},
		async flashVideoFrame( timestamp ) {
			this.updateVideoFrame( timestamp );
			await this.$nextTick();
			this.updateVideoFrame( null );
		}
	}
};
</script>
