<template>
	<div class="revision-browser__option pb-64">
		<div class="video-player">
			<video
				ref="video_player"
				class="revision-browser__option"
				:src="option.video_url"
				:alt="option.title"
				@timeupdate="updateCurrentTime"
				@durationchange="updateDuration"
				@volumechange="updateVolume"
				@click="togglePlay"
			/>

			<div v-if="duration" class="video-player__interface">
				<div class="video-player__progress-bar-wrapper">
					<input
						id="progress"
						name="progress"
						type="range"
						min="0"
						max="1000"
						v-model="video_progress"
						class="video-player__progress-bar"
						@input="updateVideoTime"
					>
					<div
						class="video-player__progress"
						:style="video_progress_style"
					/>
				</div>
				<div class="video-player__comment-timeline">
					<div
						v-for="comment, index in timeline_comments"
						:key="`timeline-comment-${index}`"
						class="video-player__comment-container"
						:class="{ 'video-player__comment-container--show-comment': visible_comment_ids.includes( comment.id ) }"
						:style="getTimelinePositionStyle( comment )"
					>
						<button
							class="video-player__comment-pip"
							@mouseover="showComment( comment )"
							@mouseleave="hideComment( comment )"
							@click="flashVideoFrame( comment.video_timestamp )"
						/>
						<div
							class="video-player__comment"
							:style="getCommentPositionStyle( comment )"
						>
							<div class="video-player__comment-header">
								<h3>
									{{ comment.author.fullName }}
								</h3>
								<div class="video-player__comment-times">
									<span class="video-player__comment-timestamp">
										{{ formatTimestamp( comment.video_timestamp ) }}
									</span>
									<span>
										{{ formatTimePassed( comment.dateCreated ) }}
									</span>
								</div>
							</div>
							<div
								class="video-player__comment-body"
								v-html="comment.comment_content"
							/>
						</div>
					</div>
				</div>
				<div class="video-player__controls">
					<div class="video-player__controls-set">
						<button
							class="video-player__play-button"
							:class="paused ? 'video-player__play-button--paused' : 'video-player__play-button--playing'"
							@click="togglePlay"
						/>
						<div
							ref="volume_controls"
							class="video-player__volume-controls"
							@mouseover="showVolumeBar"
							@mouseleave="show_volume_bar = false"
							:style="volume_controls_style"
						>
							<button
								class="video-player__volume-button"
								:class="muted || volume === 0 ? 'video-player__volume-button--muted' : 'video-player__volume-button--not-muted'"
								@click="toggleMute"
							/>
							<div class="video-player__volume-bar-wrapper">
								<input
									type="range"
									min="0"
									max="100"
									v-model="volume"
									class="video-player__volume-bar"
									@input="updatePlayerVolume"
								>
								<div
									class="video-player__volume-level"
									:style="volume_level_style"
								/>
							</div>
						</div>
					</div>

					<div class="video-player__controls-set">
						<button
							class="video-player__fullscreen-button"
							@click="video_player.requestFullscreen()"
						/>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="!commentingDisabled"
			class="flex flex--justify-center"
		>
			<button
				class="button button--fill button--icon icon icon--clock"
				@click="leaveComment"
			>
				{{ formatTimestamp( current_time ) }} &nbsp; &nbsp; Leave a comment here
			</button>
		</div>
	</div>
</template>
<script setup>
	import { ref, inject, defineProps, onMounted, computed, watch, nextTick } from 'vue';
	import { useDataStore } from '../stores/data';
	import { getTimePassed } from '../../helpers';

	const props = defineProps( {
		option: {
			required: true,
			type: Object
		},
		commentingDisabled: {
			required: false,
			type: Boolean,
			default: false
		}
	} );

	const video_player = ref( null );
	const volume_controls = ref( null );

	const duration = ref( null );
	const current_time = ref( 0 );
	const video_progress = ref( 0 );
	const paused = ref( true );
	const volume = ref( 100 );
	const muted = ref( false );
	const show_volume_bar = ref( false );

	const comments = ref( [] );
	const visible_comment_ids = ref( [] );

	const { comment_timestamp, updateCommentTimestamp } = inject( 'comment-timestamp' );
	const { video_frame, updateVideoFrame } = inject( 'video-frame' );

	const {
		getCommentsByOptionId
	} = useDataStore();

	const getComments = async () => {
		const response = await getCommentsByOptionId( parseInt( props.option.id ) );
		if ( response.data ) {
			comments.value = response.data.entries.reverse();
		}
	};

	const video_progress_style = computed( () => {
		if ( duration.value === null ) {
			return { width: "0%" }
		}
		return { width: `${ video_progress.value / 10  }%` }
	} );

	const volume_controls_style = computed( () => {
		if ( show_volume_bar.value ) {
			return { maxWidth: `${volume_controls.value.scrollWidth}px` }
		}
		return { maxWidth: null }
	} );

	const volume_level_style = computed( () => {
	 	return { width: `${volume.value}%` }
	} );

	const timeline_comments = computed( () => {
		return comments.value.filter( comment => comment.video_timestamp );
	} );

	const updateDuration = () => {
		duration.value = video_player.value.duration;
	}

	const updateCurrentTime = () => {
		current_time.value = video_player.value.currentTime;
		video_progress.value = ( current_time.value / duration.value ) * 1000;
	}

	const updateVideoTime = ( event ) => {
		const progress = event.target.value;
		video_player.value.currentTime = ( progress / 1000 ) * duration.value;
	}

	const togglePlay = () => {
		if ( video_player.value.paused ) {
			video_player.value.play();
			paused.value = false;
			return;
		}
		video_player.value.pause();
		paused.value = true;
	}

	const updatePlayerVolume = () => {
		if ( muted.value ) {
			return;
		}
		video_player.value.volume = volume.value / 100;
	}

	const toggleMute = () => {
		if ( muted.value ) {
			muted.value = false;
			updatePlayerVolume();
			return;
		}
		muted.value = true;
		video_player.value.volume = 0;
	}

	const showVolumeBar = () => {
		console.log( volume_controls.value.scrollWidth );
		show_volume_bar.value = true;
	}

	const getTimelinePositionStyle = comment => {
		const timestamp = comment.video_timestamp;
		return {
			left: `${ ( timestamp / duration.value ) * 100 }%`
		}
	}

	const getCommentPositionStyle = comment => {
		const timestamp = comment.video_timestamp;
		const timeline_position = ( timestamp / duration.value ) * 100;

		if ( timeline_position < 20 ) {
			return {
				transform: 'translateX( -10% )'
			}
		}
		if ( timeline_position > 80 ) {
			return {
				transform: 'translateX( -90% )'
			}
		}
		return {
			transform: 'translateX( -50% )'
		}
	}

	const showComment = comment => {
		if ( !visible_comment_ids.value.includes( comment.id ) ) {
			visible_comment_ids.value.push( comment.id );
		}
	}

	const hideComment = comment => {
		if ( visible_comment_ids.value.includes( comment.id ) ) {
			visible_comment_ids.value.splice( visible_comment_ids.value.indexOf( comment.id ), 1 );
		}
	}

	const formatTimestamp = timestamp_in_seconds => {
		const mins = Math.floor( timestamp_in_seconds / 60 );
		const secs = Math.floor( timestamp_in_seconds % 60 );

		return secs < 10 ? `${mins}:0${secs}` : `${mins}:${secs}`;
	}

	const formatTimePassed = date_string => {
		return getTimePassed( date_string );
	}

	const leaveComment = () => {
		updateCommentTimestamp( current_time.value );
	}

	const flashVideoFrame = async timestamp => {
		updateVideoFrame( timestamp );
		await nextTick();
		updateVideoFrame( null );
	}

	watch( video_frame, ( frame ) => {
		if ( frame !== null ) {
			video_player.value.currentTime = frame;
		}
	} );

	watch( comment_timestamp, ( timestamp ) => {
		if ( timestamp === null ) {
			getComments();
		}
	} )

	onMounted( async () => {
		await nextTick();
		getComments();
	} );
</script>